import React from "react"
import { graphql } from 'gatsby'
import {Col, Row, Container } from 'react-bootstrap'
import HeaderOne from "../components/header/header"
import Footer from "../components/footer/footer"
import CanHelp from "../components/Home/CanHelp/CanHelpStyleThree"
import ReactMarkdown from "react-markdown"
import FeaturedProperties from "../components/PropertyDetails/Similar/featured-properties"
import SEO from "../components/seo"

import StaticBanner from "../components/Static/StaticBanner/area-guide-static-banner"
import MGFav from '../components/Static/MgFavourites/MgFavouritesLocalLife'

const LocalLifeLanding = (props) => {

    const data = props.data.strapiAreaGuides
    const category = props.data.allStrapiLocalLifeCategories.edges
    const res = props.data.allStrapiRestaurants.edges


    var all_cat = [];
    for(var i=0; i < category.length; i++){
      all_cat.push(category[i].node)
    }

    function uni(data, key) {
      return [
        ...new Map(
          data.map(x => [key(x), x])
        ).values( )
      ]
    }

    const cat = uni(all_cat,it=>it.strapiId)

	var meta_desc = "What’s there to love about life in "+data.Title+"? Plenty! Start exploring your way through the area right here with local Martyn Gerrard agents."

	return (
		<div className="local-life-area-landing-page">
	        <HeaderOne />
            <SEO title={data ? data.Meta_Title : ''} description={meta_desc} />
			{data.Area_Local_Loving_Life?.Banner_Image !== null &&
                <>
                <StaticBanner Title={data.Area_Local_Loving_Life.Banner_Content ? data.Area_Local_Loving_Life.Banner_Content : ''} CTA_1_URL="test" CTA_2_URL="#" Banner_Image={data.Area_Local_Loving_Life?.Banner_Image} tag="local-life" category={data.Title} all_cat={cat} page_url={data.URL}/>
                </>
            }


            <Container className="intro">
            	<Row>
                	<Col xs={12} sm={12} lg={9}>
                		<div className="content-one">
                			<ReactMarkdown source={data.Area_Local_Loving_Life.Intro_Content} allowDangerousHtml />
                		</div>
                	</Col>
                	<Col xs={12} sm={12} lg={3} className="east-schedule">
                	</Col>
            	</Row>
            </Container>

            <MGFav data={data} category={category} res={res}/>
			<FeaturedProperties place={data.Title} />
	        <CanHelp />

	        <Footer Select_Popular_Search="Static_Contact" popularSearch="static"/>
		</div>
	)
}

export default LocalLifeLanding


export const pageQuery = graphql`query LocalLifeAreaLandingQuery($slug: String!) {
  strapiAreaGuides(strapiId: {eq: $slug}) {
    Title
    URL
    Area_Local_Loving_Life {
      Meta_Description
      Meta_Title
      Events_Intro
      Intro_Content
      Restaurants_and_Bars_Intro
      Shops_Intro
      Banner_Content
      Clubs_and_Societies_Intro
      Banner_Image {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
      }
    }
    Local_life_things_to_dos {
      URL
      Name
      Tile_Image_Label
      Tile_Image {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 120, layout: CONSTRAINED)
        }
      }
      Short_Description
      Address
      Category
      Featured
    }
  }
  allStrapiRestaurants(
    filter: {area_guide: {id: {eq: $slug}}, Publish: {eq: true}}
  ) {
    edges {
      node {
        Address
        Featured
        Name
        URL
        Tile_Image_Label
        Short_Description
        Tile_Image {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 120, layout: CONSTRAINED)
          }
        }
        strapiId
        Category {
          Name
          URL
          id
        }
      }
    }
  }
  allStrapiLocalLifeCategories(
    filter: {local_life_things_to_dos: {elemMatch: {area_guide: {eq: $slug}}}}
  ) {
    edges {
      node {
        Name
        URL
        Alias
        strapiId
        local_life_things_to_dos {
          area_guide
        }
      }
    }
  }
}
`
